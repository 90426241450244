

@import "node_modules/bulma/sass/utilities/initial-variables";
// https://www.canva.com/colors/color-wheel/
$dark-green: #3e4532;
$light-green: #8b8f84;
$lighter-green: #c5c7c2;
$lightest-green: #ececeb;
$light-blue: #A5D0E6;
$light-blue-2: #A5E6DC;
$purple: #BBA5E6;
$yellow: #d0e6a5;
$light-yellow: #e3f0c9;
$lighter-yellow: #f1f8e4;
$lightest-yellow: #fafdf6;
$red: #b34141;
// $yellow-invert: findColorInvert($yellow);
// $custom-colors: ("secondary": ($yellow, $yellow-invert));

$primary: $yellow;
$info: $yellow;
// $success: $green
$warning: $light-yellow;
$danger: $red;
$light: $lightest-green;
$dark: $dark-green;

$link: $light-green;

$border-light: $lightest-green; // panel-item-border // panel-header
// $panel-heading-
$text: $dark-green;
$text-light: $lightest-green;
$text-strong: $dark-green;
// $text: $dark-green;

$background: $lightest-green;
// $navbar-background-color: $yellow;
// $dark: $grey-darker
// $text: $grey-dark
// $card-header-color: $white;
// $card-header-background-color: $dark-green;

$panel-item-border: 1px solid $lightest-green;
$panel-heading-color: $white;
$panel-heading-background-color: $light-green;
$panel-icon-color: $light-green;

$menu-item-color: $lightest-green;
$menu-item-active-color: $dark-green;
$menu-item-active-background-color: $yellow;
$menu-item-hover-background-color: $lightest-yellow;

// $input-color: $dark-green;
// // $input-background-color: $lightest-green;
$input-color: $dark-green;
$input-background-color: $lightest-yellow;
$input-border-color: $lightest-yellow;

// $input-disabled-color: $dark-green;
// $input-disabled-background-color: $lighter-green;
// $input-disabled-border-color: $lighter-green;
// $input-icon-color: $light-green;

$input-disabled-color: $light-green;
$input-disabled-background-color: $light-yellow;
$input-disabled-border-color: $light-yellow;
$input-icon-color: $light-green;

@import "node_modules/bulma/sass/utilities/mixins";
@import "node_modules/bulma/sass/utilities/functions";
@import "node_modules/bulma/bulma.sass";

.panel-block, .panel-tabs {
    background-color: $white;
}

body {
    margin: 0;
    padding: 0;
    background-color: $dark-green;
    min-height: 100vh;
    position: relative;
}

.page-body {
    min-height: 90vh;
}

.has-text-primary > a:hover {
    color: white;
}

.popup-image-placeholder {
    width: 50px;
    height: 50px;
    background-color: gray;
}

.drawer-panel {
    position:fixed;
    background: white;
    border-radius: 5px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index:100;
    transition: transform 1s;
    height: 50px;
    padding-top:50px;
    
    .panel-heading {
        touch-action: none;
        position: absolute;
        top:0;
        width: 100%;
    }

    .panel {
        box-shadow: none;
        max-height: 100%;
        overflow:scroll;
    }
}


.map-notifcation-control {
    width: 50%;
    // position: absolute; // note this is inline as it's overridden in bulma css
    z-index: 100;
    margin-left: 25%;
    // top: 30px; note this is handled inline
    transition: all 0.3s ease-in-out;
}

.color-palette-swatch {
    width: 40px;
    height: 40px;
    display: 'inline-block';
    margin: 2px;
    cursor: pointer;

    @include tablet {
        width: 20px;
        height: 20px;
    }
}

.anchor-selector {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border: .5px solid $lighter-green;
}

.anchor-option {
    height: 20px;
    // width: 20px;
    border: .5px solid $lighter-green;
    cursor: pointer;

    &:hover {
        background-color: $light-green;
    }

    &.is-active {
        background-color: $lightest-green;
    }
}

.mapboxgl-ctrl button.active {
    background-color: $yellow;
}

.mapboxgl-ctrl button:not(:disabled):hover {
    background-color: $light-yellow;
}

.mapboxgl-ctrl-geocoder.mapboxgl-ctrl-geocoder--collapsed {
    width: 29px;
    min-width: 29px;
}

.mapboxgl-ctrl-geocoder--input {
    height: 29px;
}

.mapboxgl-ctrl-geocoder--icon-search {
    left: 5px;
}
.mapboxgl-ctrl-geocoder--icon {
    top: 5px;
}

.mapbox-gl-draw_ctrl-draw-btn {
    width: 100%;
    height: 100%;
}

#map-menu {

    @include tablet {
        .panel {
            position: absolute;
            top: 10px;
            right: 50px;
            width: 300px;
            overflow: scroll;
            max-height: calc(100% - 30px);
        }
    
        .panel.drawer-panel {
            width: 100%;
        }
    }
    

    // .panel > .panel-heading {
    //     position: relative; // needed for z-index settings
    //     z-index: 2;
    // }
}

.panel-child-container {
    transform-origin: top left;
    z-index: 1;
    &.is-collapsed {
        overflow: hidden;
        transform: rotate(-90deg) scaleY(0) scaleX(0);
        transform-origin: top left;
    }
    transition: transform 300ms;
}

.map-control-box {

    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);

    @include mobile {
        position: absolute;
        top: 150px;
        right: -50px;
        left: auto;
        bottom: auto;
        transform: rotate(90deg);
        .control > .button > span {
            transform: rotate(-90deg);
        }
    }
}